// automatic written by ruby script .. please dont edit these comments!
// storyblok attributes:
//   headline -> text / Überschrift
//   headline_level -> option
//   image -> asset / Bild
//   caption -> textarea / Bildbeschreibung (optional)
//   block_width -> option
// end of automatic

import React from "react"
import Figure from "../molecules/Figure"
import HeadlineEnhanced from "../molecules/HeadlineEnhanced"
import BlockWrapper from "../technical/BlockWrapper"

const ImageBlock = ({ block }) => {
  return (
    <>
      {block.image?.filename && (
        <BlockWrapper block={block} showHeadline="false">
          <HeadlineEnhanced
            kicker={block.kicker}
            headline={block.headline}
            headlineLevel={block.headline_level}
            subheadline={block.subheadline}
            className={`${
              block.block_width === "screen"
                ? "w-full max-w-screen-xl mx-auto px-8"
                : ""
            }`}
          />
          <Figure
            className={`${block.headline?.length > 0 ? "pt-8" : ""}`}
            image_width={block.block_width}
            image={block.image}
            aspectRatio={block.aspect_ratio}
            alt={block.alt}
            title={block.title}
            caption={block.caption}
            captionClass={block.block_width === "screen" ? "px-8 lg:px-0" : ""}
            fitIn={block.image_fitin}
          />
        </BlockWrapper>
      )}
    </>
  )
}

export default ImageBlock
